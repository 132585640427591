import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_ThemeChanger = _resolveComponent("ThemeChanger")!
  const _component_Meta = _resolveComponent("Meta")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ThemeChanger, null, {
      default: _withCtx(() => [
        _createVNode(_component_PageHeader, {
          class: "mt-32 lg:mt-48",
          header: "Privacy policy"
        }),
        _createVNode(_component_Grid, { class: "mt-32" }, {
          default: _withCtx(() => [
            _createVNode(_component_Paragraph, { innerHTML: _ctx.htmlContent }, null, 8, ["innerHTML"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_Meta, { meta: [ { name: 'robots', content: 'noindex' } ] })
  ], 64))
}