

    import { defineComponent, onBeforeMount, PropType, onBeforeUnmount } from 'vue'

    interface meta {
        name: string,
        content: string
    }

    export default defineComponent({
        name: 'MetaInfo',
        props: {
            meta: { type: Array as PropType<Array<meta>>, default: () => [] }
        },
        setup(props) {

            const metaElements = []

            const createMetaElement = (name: string, value: string, isItemProp = false) => {

                if (value) {

                    const attribute = isItemProp ? 'itemprop' : 'name'
                    const metaItem: HTMLMetaElement = document.querySelector(`meta[${ attribute }="${ name }"]`) ?? document.createElement('meta')

                    metaItem.setAttribute(attribute, name)
                    metaItem.content = value

                    return metaItem

                }

            }

            onBeforeMount(() => {

                for (const meta of props.meta) {

                    metaElements.push(createMetaElement(meta.name, meta.content))

                }

                for (const element of metaElements) {

                    if (element) {

                        document.head.appendChild(element)

                    }

                }

            })

            onBeforeUnmount(() => {

                for (const element of metaElements) {

                    if (element) {

                        document.head.removeChild(element)

                    }

                }

            })

            return () => []

        }
    })

